import React, {
  useState,
  useImperativeHandle,
  forwardRef,
  useEffect,
} from "react";
import MenuItem from "../components/menuItem";
import FloatingWindow from "../components/floatingWindow";
import QuinnAI from "../components/quinnAI";
import TempQuinnAi from "../components/tempQuinnAi";
import Logbook from "../components/logbook";
import Reservations from "../components/reservations";
import useTheme from "../components/theme";

type ComponentKey = "quinnAI" | "logbook" | "reservations";

interface CardBackProps {
  onThemeChange: (newTheme: string) => void;
  onCardClick: () => void;
}

const descriptions = [
  "software person",
  "computer guy",
  "code writer",
  "bug adder",
  "food enjoyer",
  "prod destroyer",
  "test breaker",
];

const componentMapping: Record<ComponentKey, React.ReactNode> = {
  quinnAI: <QuinnAI />,
  logbook: <Logbook />,
  reservations: <Reservations />,
};

function useWordCycle(words: string[]): [string, () => void] {
  const [currentWord, setCurrentWord] = useState<string>(
    words[Math.floor(Math.random() * words.length)]
  );

  const handleClick = (): void => {
    const currentIndex: number = words.indexOf(currentWord);
    const nextIndex: number = (currentIndex + 1) % words.length;
    setCurrentWord(words[nextIndex]);
  };

  return [currentWord, handleClick];
}

function isComponentKey(key: any): key is keyof typeof componentMapping {
  return key in componentMapping;
}

const CardBack: React.FC<CardBackProps> = ({ onThemeChange, onCardClick }) => {
  const [currentDescription, handleDescriptionClick] =
    useWordCycle(descriptions);
  const [isFloatingWindowVisible, setFloatingWindowVisible] = useState(false);
  const [currentComponent, setCurrentComponent] =
    useState<React.ReactNode>(null);
  const [theme, toggleTheme] = useTheme();
  const themeName = theme === "moon" ? "high noon" : "call of the night";
  const themeDescription =
    theme === "moon" ? "light, citrus, bold" : "dark, smoky, subtle";
  const [showVideo, setShowVideo] = useState(false);

  const toggleVideo = () => {
    setShowVideo(!showVideo);
  };

  const handleThemeToggle = () => {
    toggleTheme();
  };

  useEffect(() => {
    onThemeChange(theme);
  }, [theme]);

  const toggleFloatingWindow = (componentKey?: string) => {
    if (componentKey && isComponentKey(componentKey)) {
      setCurrentComponent(componentMapping[componentKey]);
    } else {
      console.error("Invalid component key:", componentKey);
    }
    setFloatingWindowVisible(!isFloatingWindowVisible);
  };

  return (
    <div className="card_back">
      {isFloatingWindowVisible && (
        <FloatingWindow
          content={currentComponent}
          onClose={() => setFloatingWindowVisible(false)}
        />
      )}
      {showVideo ? (
        <div className="disco_div">
          <img
            src={theme === "sun" ? "disco.gif" : "disco_white.gif"} // Change the src based on the theme
            alt="disco ball"
            className="disco_gif"
          />
        </div>
      ) : null}

      <div className="column1">
        {window.innerWidth < 700 && (
          <img
            className="logo"
            src={theme === "moon" ? "./cat_white.png" : "./cat.png"}
            alt="cat"
          />
        )}
        <h1>quinn ha</h1>
        <h2 className="card_subtitle_back">
          <a className="clickable" id="corner" onClick={handleDescriptionClick}>
            {currentDescription}
          </a>{" "}
        </h2>
        <br />
        <h4 className="menu__section">appetizers</h4>
        <hr />
        <MenuItem
          name="based in nyc"
          cost={17}
          description="toronto-raised, organic"
        />
        <MenuItem
          name="interests"
          cost={15}
          description="tech, accessibility, food"
        />
        <MenuItem
          name="competencies"
          cost={13}
          description="android, java, javascript, python, react"
        />
        <br />
        <h4 className="menu__section">entrees</h4>
        <hr />
        <MenuItem
          name="swe @ google"
          cost={62}
          description="android platform, docs, sheets, slides"
        />
        <MenuItem
          name="swe intern @ google"
          cost={50}
          description="accessibility, docs, sheets, slides"
        />
        <MenuItem
          name="pm intern @ purolator"
          cost={28}
          description="purolator your way, product, delivery"
        />
        <MenuItem
          name="swe intern @ canadian space agency"
          cost={20}
          description="nasa code, satellites, space"
        />
        <MenuItem
          name="qa intern @ eq bank"
          cost={16}
          description="web, mobile, automation"
        />
        <br />
        <h4 className="menu__section">desserts</h4>
        <hr />
        <MenuItem
          name="quinnha.menu"
          cost={21}
          description="presented tableside"
          onClick={onCardClick}
        />
        <MenuItem
          name="quinn ai"
          cost={23}
          description="non-deterministic, ai"
          onClick={() => toggleFloatingWindow("quinnAI")}
        />
        <MenuItem
          name="big if true"
          cost={22}
          description="code extension, large"
          isLink={true}
          linkUrl="https://marketplace.visualstudio.com/items?itemName=big-if-true.big-if-true"
        />
        <MenuItem
          name="ride home"
          cost={25}
          description="nostalgia, toronto transit, immersive"
          isLink={true}
          linkUrl="https://ridehome.netlify.app/"
        />
      </div>
      <div className="column2">
        {window.innerWidth >= 700 && (
          <img
            className="logo"
            src={theme === "moon" ? "./cat_white.png" : "./cat.png"}
            alt="cat"
          />
        )}
        <h4 className="menu__section">wines (5oz)</h4>
        <hr />
        <MenuItem
          name="resume"
          cost={39}
          description="formal, bullet points, text"
          isLink={true}
          linkUrl="https://drive.google.com/file/d/1kyNxQvCrnNk9PfwrWduGhLy0cyZ1ucZN/view?preview"
        />
        <MenuItem
          name="linkedin"
          cost={23}
          description="less formal, posts"
          isLink={true}
          linkUrl="https://www.linkedin.com/in/quinn-ha/"
        />
        <MenuItem
          name="github"
          cost={16}
          description="code, projects"
          isLink={true}
          linkUrl="https://github.com/quinnha"
        />
        <MenuItem
          name="email"
          cost={26}
          description="quinnha.hello@gmail.com"
          isLink={true}
          linkUrl="mailto:quinnha.hello@gmail.com"
        />
        <br />
        <h4 className="menu__section">cocktails (4oz)</h4>
        <hr />
        <MenuItem
          name="twitter"
          cost={25}
          description="tweets, personality, flavour"
          isLink={true}
          linkUrl="https://twitter.com/qvinnh"
        />
        <MenuItem
          name="readings"
          cost={23}
          description="fundamentals, favourites, futures"
          isLink={true}
          linkUrl="https://curius.app/quinn-ha"
        />
        <MenuItem
          name="shyboy afterparty"
          cost={19}
          description="inspired by wenwen, yuzu, pandan"
          onClick={toggleVideo}
        />
        <br />
        <h4 className="menu__section">non-alcoholics</h4>
        <hr />
        <MenuItem
          name="writings"
          cost={14}
          description="thoughts, findings, expression"
          isLink={true}
          linkUrl="https://quinnha.substack.com/"
        />
        <MenuItem
          name={themeName}
          cost={9}
          description={themeDescription}
          onClick={handleThemeToggle}
        />
        <br />

        <p className="menu__item clickable">
          <a onClick={() => toggleFloatingWindow("reservations")}>
            reservations
          </a>
        </p>
        <p className="menu__item clickable">
          <a
            href="https://quinnha.substack.com/p/a-new-personal-website"
            className="clickable"
            target="_blank"
            rel="noreferrer"
          >
            quinnha © 2024
          </a>
        </p>
      </div>
    </div>
  );
};

export default CardBack;
